@import url('https://fonts.googleapis.com/css2?family=Fahkwang:wght@300;400;500;600;700&display=swap');

:root {
    --primary-color: #3b82f6;
    --primary-dark: #2563eb;
    --primary-light: #60a5fa;
    --secondary-color: #8b5cf6;
    --text-color: #f8fafc;
    --text-muted: #94a3b8;
    --background-dark: #0f172a;
    --background-light: #1e293b;
    --card-background: #1e293b;
    --card-hover: #2d3748;
    --error-color: #ef4444;
    --error-light: #fee2e2;
    --success-color: #22c55e;
    --success-light: #dcfce7;
    --warning-color: #f59e0b;
    --warning-light: #fef3c7;
    --info-color: #3b82f6;
    --info-light: #dbeafe;
    --border-color: rgba(255, 255, 255, 0.1);
    --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --shadow-xl: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  }
  
  /* Base Container Styles */
  .munchmate-container {
    background: linear-gradient(135deg, var(--background-dark), var(--background-light));
    border-radius: 1rem;
    padding: 1.5rem;
    width: 100%;
    max-width: 100%;
    margin: 1rem 0;
    box-shadow: var(--shadow-xl);
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .munchmate-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.25rem;
    background: linear-gradient(to right, var(--primary-color));
    z-index: 1;
  }
  
  /* Header Styles */
  .munchmate-header {
    text-align: center;
    margin-bottom: 2rem;
    position: relative;
  }
  
  .munchmate-title {
    font-size: 3.5rem;  /* Increased from 2.5rem */
    font-weight: 800;
    background: linear-gradient(to right, var(--primary-light), var(--secondary-color));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 0.75rem;
    letter-spacing: -0.025em;
    line-height: 1.2;
}
  
  .munchmate-tagline {
    color: var(--text-muted);
    font-size: 1.1rem;
    max-width: 36rem;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  /* Form Elements */
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 2rem;
    position: relative;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .input-group {
    position: relative;
    transition: transform 0.2s ease;
    width: 100%;
  }
  
  .input-group:hover {
    transform: translateY(-2px);
  }
  
  .input-label {
    display: block;
    color: var(--text-color);
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.75rem;
    transition: color 0.2s ease;
  }
  
  .input-field {
    width: 100%;
    padding: 1rem 1.25rem;
    background-color: rgba(255, 255, 255, 0.05);
    border: 2px solid var(--border-color);
    border-radius: 0.75rem;
    color: var(--text-color);
    font-size: 1rem;
    transition: all 0.2s ease;
    box-shadow: var(--shadow-sm);
    box-sizing: border-box;
  }
  
  .input-field:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
    background-color: rgba(255, 255, 255, 0.08);
  }
  
  /* Button Styles */
  .generate-button {
    width: 100%;
    padding: 1rem 1.5rem;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    border: none;
    border-radius: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    box-shadow: var(--shadow-md);
    max-width: 800px;
    margin: 0 auto;
  }
  
  .generate-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
  }
  
  /* Result Section */
  .recipe-result {
    margin-top: 2rem;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
    border: 1px solid var(--border-color);
    position: relative;
    animation: fadeIn 0.5s ease;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .recipe-section {
    padding: 1.25rem;
    border-bottom: 1px solid var(--border-color);
    transition: background-color 0.2s ease;
  }
  
  .recipe-section h3 {
    color: var(--primary-light);
    margin-bottom: 1rem;
    font-size: 1.5rem;  /* Increased from 1.1rem */
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
  
  /* Benefits Grid */
  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.25rem;
    margin-top: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .recipe-section p {
    font-size: 1rem;  /* Added to control ingredient text size */
    line-height: 1.6;
    color: var(--text-color);
  }
  
  .benefit-card {
    background: rgba(255, 255, 255, 0.03);
    padding: 1.5rem;
    border-radius: 1rem;
    border: 1px solid var(--border-color);
    transition: all 0.3s ease;
  }

  .benefit-icon {
    font-size: 1.75rem;  /* Increased icon size */
    margin-bottom: 0.5rem;
  }

  .benefit-card h3 {
    font-size: 1.5rem;  /* Increased from default */
    color: var(--primary-light);
    margin: 0.75rem 0;
  }
  
  /* Loading State */
  .loading-state {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
  }
  
  .loading-spinner {
    width: 1.25rem;
    height: 1.25rem;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top-color: white;
    border-radius: 50%;
    animation: spin 1s cubic-bezier(0.55, 0.15, 0.45, 0.85) infinite;
  }
  
  /* Error States */
  .error-message {
    background-color: rgba(239, 68, 68, 0.1);
    border: 1px solid var(--error-color);
    color: var(--error-color);
    padding: 1rem;
    border-radius: 0.75rem;
    margin: 1rem auto;
    max-width: 800px;
    text-align: center;
  }

  .fitness-goals-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Dynamically adjusts to screen size */
    gap: 1rem;
    margin-bottom: 1.5rem;
    font-family: 'Fahkwang', sans-serif;
    padding: 0 1rem; /* Adds padding to prevent clipping */
}

.fitness-goal-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.875rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'Fahkwang', sans-serif;
  font-weight: 400;
  letter-spacing: 0.02em;
  justify-content: center; /* Ensures button content is centered */
}

.fitness-goal-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.fitness-goal-button.selected {
  background: rgba(59, 130, 246, 0.2);
  border-color: var(--primary-color);
}

.fitness-checkbox {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    border: 2px solid rgba(255, 255, 255, 0.2);
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    position: relative;
}

.fitness-checkbox:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.fitness-checkbox:checked::after {
    content: '';
    position: absolute;
    left: 5px;
    top: 2px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Update these existing classes */
.input-field {
    background: rgba(255, 255, 255, 0.05);
    font-family: 'Fahkwang', sans-serif;
    font-weight: 400;
    letter-spacing: 0.01em;
}

.generate-button {
    background: var(--primary-color);
    margin-top: 1rem;
    font-family: 'Fahkwang', sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
}

  /* Enhanced Responsive Design */
@media screen and (max-width: 1024px) {
  .munchmate-container {
    margin: 0.875rem;
    padding: 1.25rem;
  }

  .munchmate-title {
    font-size: 2.75rem;
  }

  .input-container {
    max-width: 90%;
  }

  .generate-button,
  .recipe-result {
    max-width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .munchmate-container {
    margin: 0 auto; /* Center the entire container */
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center; /* Center-align the content */
  }

  .munchmate-title {
    font-size: 2.25rem;
    margin-bottom: 0.5rem;
  }

  .munchmate-tagline {
    font-size: 1rem;
    max-width: 90%;
  }

  .munchmate-header {
    margin-bottom: 1.5rem;
  }

  .benefits-grid {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1rem;
    margin-top: 1.5rem;
  }

  .input-container {
    gap: 1rem;
  }

  .input-group {
    margin-bottom: 0.5rem;
  }

  .input-label {
    font-size: 0.925rem;
    margin-bottom: 0.5rem;
  }

  .input-field {
    padding: 0.875rem 1rem;
    font-size: 0.925rem;
    border-radius: 0.5rem;
  }

  .generate-button {
    padding: 0.875rem 1.25rem;
    font-size: 0.925rem;
    border-radius: 0.5rem;
  }

  .recipe-section {
    padding: 1.125rem;
  }

  .recipe-section h3 {
    font-size: 1.25rem;
    margin-bottom: 0.875rem;
  }

  .fitness-goals-grid {
    display: flex; /* Switch from grid to flex for better centering */
    flex-wrap: wrap; /* Allow buttons to wrap if necessary */
    justify-content: center; /* Center buttons horizontally */
    gap: 1rem; /* Maintain spacing between buttons */
}

.fitness-goal-button {
    width: auto; /* Allow buttons to shrink appropriately */
    min-width: 100px; /* Maintain a reasonable button size */
    text-align: center; /* Center-align button content */
  }
}

@media screen and (max-width: 480px) {
  .munchmate-container {
    margin: 0 auto; /* Center the entire container */
    padding: 0.75rem;
    border-radius: 0.5rem;
    text-align: center; /* Center-align the content */
  }

  .munchmate-container::before {
    height: 0.188rem;
  }

  .munchmate-title {
    font-size: 1.875rem;
    margin-bottom: 0.375rem;
  }

  .munchmate-tagline {
    font-size: 0.875rem;
    line-height: 1.4;
  }

  .munchmate-header {
    margin-bottom: 1.25rem;
  }

  .input-container,
  .generate-button,
  .recipe-result {
    max-width: 100%;
  }

  .input-label {
    font-size: 0.875rem;
    margin-bottom: 0.375rem;
  }

  .input-field {
    padding: 0.75rem 0.875rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
  }

  .generate-button {
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
  }

  .benefits-grid {
    grid-template-columns: 1fr;
    gap: 0.875rem;
    margin-top: 1.25rem;
  }

  .benefit-card {
    padding: 1.125rem;
    border-radius: 0.5rem;
  }

  .benefit-card h3 {
    font-size: 1.25rem;
    margin: 0.5rem 0;
  }

  .benefit-icon {
    font-size: 1.5rem;
  }

  .recipe-section {
    padding: 0.875rem;
  }

  .recipe-section h3 {
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
  }

  .recipe-section p {
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .error-message {
    padding: 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    margin: 0.75rem auto;
  }

  .fitness-goals-grid {
    flex-direction: column; /* Stack buttons vertically on very small screens */
    gap: 0.75rem; /* Adjust spacing for smaller screens */
  }

.fitness-goal-button {
    width: 100%; /* Make buttons full width for vertical stacking */
    text-align: center; /* Center-align button content */
  } 
}

/* New addition for very small devices */
@media screen and (max-width: 360px) {
  .munchmate-container {
    margin: 0.375rem;
    padding: 0.625rem;
  }

  .munchmate-title {
    font-size: 1.625rem;
  }

  .munchmate-tagline {
    font-size: 0.8125rem;
  }

  .input-field,
  .generate-button {
    padding: 0.625rem 0.75rem;
  }

  .benefit-card {
    padding: 1rem;
  }
}