.project {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.project-image {
  width: calc(66.67% - 25px);
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  border: 5px solid white;
  box-sizing: border-box;
  max-width: 100%;
}

.project-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 200px;
}

.project-title {
  font-size: 1em;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 15px;
  font-style: italic;
}

.project-description {
  font-size: 0.85em;
  color: #ffffff;
}

.project p {
  margin-top: 0;
}

.project-title a {
  color: #ffffff;
  text-decoration: underline;
  transition: color 0.2s ease;
  font-size: 23px;
}

.project-title a:hover {
  color: #cccccc;
}

@media screen and (max-width: 768px) {
  .project {
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 0 15px;
  }

  .project-content {
    order: -1;
    width: 100%;
    margin-bottom: 20px;
  }

  .project-image {
    width: 100%;
    max-width: 375px;
    margin: 0;
    margin-right: 20px;
    box-sizing: border-box;
  }

  .project-title {
    font-size: 4.625vw;
    margin-bottom: 15px;
    margin-left: 20px;
  }

  .project-description {
    font-size: 3.2vw;
    margin-left: 20px;
  }

  .nasa-rover-image,
  .exoskeleton-image {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .project {
      flex-direction: column;
      align-items: center;
  }

  .project-image {
      width: 90%; /* Adjust width for smaller screens */
      margin: 0 auto; /* Center image */
  }

  .project-content {
      margin: 0 auto; /* Center project content */
      text-align: center; /* Align text centrally */
      width: 90%; /* Prevent content from stretching */
      background-color: rgba(255, 255, 255, 0.1); /* Optional subtle background */
      border-radius: 10px;
      padding: 15px;
  }

  .project-title {
      font-size: 18px; /* Consistent font size */
      margin: 10px auto;
  }

  .project-description {
      font-size: 16px;
      margin: 10px auto;
      padding: 10px;
  }
}


@media screen and (min-width: 769px) {
  .project-image {
    width: 222.5px;
  }

  .project-title {
    font-size: 1.275em;
  }

  .project-description {
    font-size: 1em;
  }

  .project-title a {
    margin-left: 0px;
  }

  .rover-simulation {
    width: calc(100% + 222.5px + 25px);
    margin-right: -247.5px;
  }
}
