/* Import Fonts */
@import url('https://fonts.googleapis.com/css2?family=Fahkwang:wght@300;400;500;600;700&family=Play:wght@400;700&family=Space+Grotesk:wght@300..700&display=swap');

.brain-activity-chart {
    width: 100%;
    max-width: 1400px;
    margin: 10px auto;
    padding-bottom: 20px;
    background: linear-gradient(135deg, #111 0%, #1a1a1a 100%);
    border: 5px solid;
    border-image: linear-gradient(45deg, #7873f5, #ff6ec4) 1;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: 'Play', sans-serif; /* Default font for general text */
    color: #fff;
    position: relative;
    overflow: hidden;
}

.brain-activity-chart::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, transparent 0%, rgba(0,0,0,0.8) 100%),
                linear-gradient(45deg, rgba(120, 115, 245, 0.05) 0%, rgba(255, 110, 196, 0.05) 100%);
    z-index: 0;
}

.header {
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.title {
    font-size: clamp(60px, 4vw, 32px);
    font-weight: 700; /* Bold for emphasis */
    letter-spacing: 0.5px;
    background: linear-gradient(to right, #7873f5, #ff6ec4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 10px rgba(255,255,255,0.1);
    margin: 0;
    padding: 20px 0;
    margin-bottom: -30px;
    font-family: 'Fahkwang', sans-serif; /* Using Fahkwang for titles */
}

.content-row {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.chart-content {
    width: 100%;
    height: 350px;
    min-width: 1200px;
    font-family: 'Fahkwang', sans-serif; /* Optional alternative font for charts */
}

.button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
    margin-top: -15px;
    z-index: 10;
}

.button {
    padding: 12px 25px;
    background: linear-gradient(145deg, #7873f5, #ff6ec4);
    color: #fff;
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    font-size: clamp(12px, 2vw, 16px);
    cursor: pointer;
    font-family: 'Play', sans-serif; /* Play font for buttons */
    font-weight: 400;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.button:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(255, 110, 196, 0.5),
                0 0 30px rgba(120, 115, 245, 0.3);
    background: linear-gradient(145deg, #ff6ec4, #7873f5);
    border-color: #fff;
}

.button:active {
    transform: scale(0.95);
    box-shadow: 0 0 5px rgba(255, 110, 196, 0.5);
}

@media (max-width: 768px) {
    .brain-activity-chart {
        padding: 15px;
    }

    .chart-content {
        min-width: unset;
        height: 250px;
    }

    .button {
        padding: 10px 20px;
    }
}

@media (max-width: 480px) {
    .brain-activity-chart {
        padding: 10px;
    }

    .chart-content {
        height: 200px;
    }

    .title {
        font-size: 1em;
    }

    .button {
        padding: 8px 15px;
        font-size: 0.75em;
    }
}
