.gallery-grid {
  display: grid;
  gap: 10px;
  padding: 10px 0;
}

.gallery-item {
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 3px solid white; /* Added border */
  box-sizing: border-box; /* Include border in the element's total width and height */
}

.gallery.expanded-margin {
  margin-bottom: 40px;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block; /* Prevents potential spacing issues */
}

/* Responsive Styles */

/* Large screens: 4x2 layout */
@media screen and (min-width: 1024px) {
  .gallery-grid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

/* Medium screens: 2x4 layout */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

/* Small screens: Match albums layout */
@media screen and (max-width: 767.98px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr); /* Match albums grid columns */
    grid-template-rows: repeat(6, 1fr); /* Match albums grid rows */
    gap: 10px; /* Match albums gap */
    padding: 0 3px; /* Add padding to the grid to prevent overflow */
    overflow: hidden; /* Prevent overflow from the grid */
  }

  .gallery-item {
    width: 100%; /* Ensures full width within the grid cell */
    margin: 0; /* Remove auto margin to prevent centering issues */
    box-sizing: border-box; /* Include borders in width calculation */
  }

  .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; /* Prevents any unexpected margins */
  }
}
